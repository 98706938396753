import React from "react";

const Spinner = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#052B48]">
      <img
        src="/loader.gif" 
        alt="Loading..."
        className="w-32 h-32" 
      />
    </div>
  );
};

export default Spinner;
