import React, { useState } from "react";

function CtfPrize() {
  const [isPressed, setIsPressed] = useState(false);

  const handleButtonClick = () => {
    setIsPressed(!isPressed);
  };

  return (
    <div className="relative bg-[#8A79C9] h-screen w-full text-white select-none">
      <div className="absolute top-1/3  left-1/2 transform -translate-y-1/2 -translate-x-1/2 md:left-1/4 md:top-1/2 md:translate-x-0">
        <img
          src="/codecraftpage/prize/15kprizepool.svg"
          alt="Ribbon"
          className="w-[150vw] h-[100vh] md:w-[20vw] md:h-[40vh]"
        />
      </div>
      <img
        src="/codecraftpage/prize/sunglasses.gif"
        alt="Sunglasses"
        className="hidden md:block absolute left-1/4 bottom-0 w-[20vw] h-auto transform scale-x-[-1] select-none"
      />
      {isPressed && (
        <img
          src="/codecraftpage/prize/confetti.gif"
          alt="Confetti"
          className="absolute left-0 top-1/4 md:top-0 w-[150vw] h-[40vh] md:w-auto md:h-[100vh] select-none"
        />
      )}
      <div className="absolute right-0 bottom-0 mx-10">
        <img
          src={
            isPressed
              ? "/codecraftpage/prize/ctfbingomachinepressed.svg"
              : "/codecraftpage/prize/ctfbingomachine.svg"
          }
          alt="Bingo Machine"
          className="w-full md:h-[90vh] h[100vh] select-none"
        />
        <div
          className="absolute inset-0 flex items-center justify-center cursor-pointer select-none"
          onClick={handleButtonClick}
        ></div>
      </div>
    </div>
  );
}

export default CtfPrize;
