import React from "react";
import { motion } from "framer-motion";
import UiCard from "./UiCard";
import CtfCard from "./CtfCard";
import DsaCard from "./DsaCard";

const TentComponent = () => {
  const animationSettings = {
    initial: { y: -300, opacity: 0 },
    whileInView: { y: 0, opacity: 1 },
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 12,
      duration: 1.2,
      delay: 0.3,
    },
    variants: {
      hidden: { opacity: 0, y: 100 },
      visible: { opacity: 1, y: -10 },
    },
    initial: "hidden",  // Initial state
    whileInView: "visible",  // Trigger visible state when in view
    viewport: { once: true }, // This ensures the animation triggers only once
  };

  const prizePoolAnimation = {
    initial: { y: 200, opacity: 0 },
    whileInView: { y: 140, opacity: 1 },
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 12,
      duration: 1.2,
      delay: 0.3,
    },
    variants: {
      hidden: { opacity: 0, y: 100 },
      visible: { opacity: 1, y: -10 },
    },
    initial: "hidden",  // Initial state
    whileInView: "visible",  // Trigger visible state when in view
    viewport: { once: true }, // This ensures the animation triggers only once
  };

  return (
    <>
      <div className="relative w-full bg-[#052B48] h-[150px] sm:h-[200px] lg:h-[300px] xl:h-[400px]">
      <div className="absolute inset-0 z-0">
        <img
          src="assets/Noise & Texture.svg"
          alt="Noise & Texture"
          className="w-full h-full object-cover"
        />
      </div>
        {/* <img
          src="/codecraftpage/uppercloud.svg"
          alt="Upper Cloud"
          className="absolute bottom-2 sm:-bottom-2 w-full  z-10"
        /> */}
        <img src="/codecraftpage/tent/leftuppercloud.svg" 
        alt="left upper cloud"
        className="absolute bottom-1 sm:-bottom-2 w-1/3  z-10"
        />
        <img src="/codecraftpage/tent/rightuppercloud.svg" 
        alt="right upper cloud"
        className="absolute bottom-1 sm:-bottom-2 right-0 w-1/3  z-10"
        />
        


        <motion.div
          className="relative flex justify-center"
          {...prizePoolAnimation}
        >
          <img
            src="/codecraftpage/tent/35kprizepool.svg"
            alt="Prize Pool"
            className="absolute -bottom-24 sm:-bottom-40 lg:-bottom-20 xl:-bottom-72 w-[120px] sm:w-[230px] z-20"
          />
        </motion.div>

        {/* <img
          src="/codecraftpage/lowercloud.svg"
          alt="Lower Cloud"
          className="absolute bottom-0 sm:-bottom-2  w-full z-10"
        /> */}
        <img src="/codecraftpage/tent/leftlowercloud.svg" 
        alt="left lower cloud"
        className="absolute -bottom-1 sm:-bottom-3 w-1/3  z-10"
        />
        <img src="/codecraftpage/tent/rightlowercloud.svg" 
        alt="right lower cloud"
        className="absolute -bottom-1 sm:-bottom-3 right-0 w-1/3  z-10"
        />
      </div>
      <div className="bg-[#8DC6F2]">
        <div className="relative w-full flex justify-center -mt-16 ">
          <img
            src="/codecraftpage/tent/newtent.svg"
            alt="Tent"
            className="w-full  xl:w-11/12 z-10"
          />
        </div>

        <div className="bg-[#8DC6F2]">
          <img
            src="/codecraftpage/tent/curlss.svg"
            alt="Curl Decoration"
            className="absolute xl:w-11/12 left-0 xl:left-16 z-20"
          />
          <div className=" relative h-16 sm:h-24 bg-[#8DC6F2] flex justify-center items-center">
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 1"
              className=" z-15 mx-2 absolute left-[30%] lg:left-[17%] w-2 lg:w-3"
              {...animationSettings}
            />
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 2"
              className=" z-15 mx-2 absolute left-[50%] w-2 lg:w-3"
              {...animationSettings}
            />
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 3"
              className=" z-15 mx-2 absolute left-[69%] lg:left-[83%] w-2 lg:w-3"
              {...animationSettings}
            />
          </div>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-0 xl:gap-6 bg-[#8DC6F2] px-4 -mt-1 min-[410px]:-mt-2 sm:-mt-0 z-20">
          <motion.div className="flex justify-center -mt-3" {...animationSettings}>
            <UiCard />
          </motion.div>
          <div className="sm:hidden relative h-16 sm:h-24 bg-[#8DC6F2] flex justify-center items-center">
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 1"
              className=" sm:hidden z-15 mx-2 absolute left-[30%] xl:left-[17%] -mt-2 w-2 xl:w-3"
              {...animationSettings}
            />
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 2"
              className="sm:hidden z-15 mx-2 absolute left-[50%] -mt-2 w-2 xl:w-3"
              {...animationSettings}
            />
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 3"
              className="sm:hidden z-15 mx-2 absolute left-[69%] xl:left-[83%] -mt-2 w-2 xl:w-3"
              {...animationSettings}
            />
          </div>

          <motion.div className="flex justify-center -mt-3" {...animationSettings}>
            <CtfCard />
          </motion.div>
          <div className="sm:hidden relative h-16 sm:h-24 bg-[#8DC6F2] flex justify-center items-center">
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 1"
              className="sm:hidden z-15 mx-2 absolute left-[30%] xl:left-[17%] -mt-2 w-2 xl:w-3"
              {...animationSettings}
            />
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 2"
              className="sm:hidden z-15 mx-2 absolute left-[50%] -mt-2 w-2 xl:w-3"
              {...animationSettings}
            />
            <motion.img
              src="/codecraftpage/tent/bar.svg"
              alt="Bar Decoration 3"
              className="sm:hidden z-15 mx-2 absolute left-[69%] -mt-2 xl:left-[83%] w-2 xl:w-3"
              {...animationSettings}
            />
          </div>

          <motion.div className="flex justify-center -mt-3" {...animationSettings}>
            <DsaCard />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default TentComponent;
