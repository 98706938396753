import React, { useEffect } from "react";
import UIuxPage from "../components/codecraft/UiuxCodecraft";
import UiuxPrize from "../components/codecraft/UiuxPrize";
import Navbar from "../../src/components/Navbar";
import Footer from "../../src/components/Footer";

const UXPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div>
      <Navbar />
      {/* Increased padding below Navbar */}
      <div className="pt-[120px]"> {/* Adjust `120px` or more as needed */}
        <UIuxPage />
        <UiuxPrize />
      </div>
      <Footer />
    </div>
  );
};

export default UXPage;