import React, { useState, useEffect } from "react";

const CTFCodecraft = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size on load and resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 400); // Adjust 768px as per your requirement
    };

    handleResize(); // Check screen size on initial load
    window.addEventListener("resize", handleResize); // Update on resize

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // handlesubmit button
  const handleSumbit = async () => {
    window.open("https://forms.gle/sLwBJSxt4TNAWyMY6", "_blank");
  };
  return (
    <div className="flex flex-col items-center space-y-8 px-4 mt-6 md:mt-14">
      {/* Main Section */}
      <div className="flex flex-col w-full h-full justify-center items-center relative">
        {/* Image Container */}
        <div className="w-[90vw] h-[70%] flex-shrink-0">
          <img
            src={isMobile ? "/assets/ctf.png" : "/assets/ctf.png"} // Conditionally change src
            className="object-cover w-full h-full"
            alt="CTF Event"
          />
          {/* Button */}
          <div
            className={`absolute ${
              isMobile ? "bottom-3 right-[47%]" : "bottom-[15%] right-[37%]"
            } right-1/3 transform translate-x-1/2`}
          >
            <button
              className="bg-[#EA417E] lg:px-9 lg:py-2 rounded-lg text-white hover:bg-pink-600 transition-all lg:text-lg sm:px-5 sm:py-1 max-[640px]:text-[6px]  max-[640px]:px-1  max-[640px]:py-1  md:mr-32 lg:mr-40 xl:mr-72 "
              onClick={handleSumbit}
            >
              Register
            </button>
          </div>
        </div>
      </div>
      {/* About Section */}
      <div
        className="flex flex-col md:flex-row   space-y-6 md:space-y-0 md:space-x-6 
                w-[90vw] "
      >
        {/* Left Section */}
        <div className="md:w-2/3 flex flex-col items-centre ">
          <h1 className="text-[#000000] lg:text-2xl min-[400px]:text-[15px] font-bold mb-4">
            About the Hackathon
          </h1>
          <p className="text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px] mb-4">
            CTF is an exhilarating cybersecurity competition designed to test
            your problem-solving and ethical hacking skills. Participants will
            tackle challenges in cryptography, web security, reverse
            engineering, and forensics to retrieve hidden flags. With a 4-hour
            time limit, individuals or teams of two will race against time to
            secure the top spot on the leaderboard. This event is perfect for
            anyone passionate about cybersecurity and ready to think like a
            hacker!
          </p>

          {/* Added Eligibility Section */}
          <h2 className="text-[#000000] lg:text-2xl min-[400px]:text-[15px] font-semibold mb-2">
            Event Format
          </h2>
          <ul className="text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px] mb-4">
            Challenges include cryptography, web security, reverse engineering,
            and more.
            <li>Retrieve and submit flags in the specified format.</li>
          </ul>

          {/* Added Tools Section */}
          <h2 className="text-[#000000] lg:text-2xl min-[400px]:text-[15px] font-semibold mb-2">
            Event Duration
          </h2>
          <p className="text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px] mb-4">
            The competition will span 4 hours.
          </p>

          {/* Added Team Size Section */}
          <h2 className="text-[#000000] lg:text-2xl min-[400px]:text-[15px] font-semibold mb-2">
            Team Size
          </h2>
          <p className="text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px] mb-4">
            1-2 members per team.
          </p>

          {/* Added Event Format Section */}
          <h2 className="text-[#000000] lg:text-2xl min-[400px]:text-[15px] font-semibold mb-2">
            Rules
          </h2>
          <ul className="space-y-2 text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px] mb-4">
            <li>
              {" "}
              Brute-forcing, seeking external help, or causing unnecessary
              traffic will result in disqualification.
            </li>
            <li>Moderators' decisions are final and binding.</li>
          </ul>

          {/* Added Judging Criteria Section */}
          <h2 className="text-[#000000] lg:text-2xl min-[400px]:text-[15px] font-semibold mb-2">
            Judging Criteria
          </h2>
          <ul className="space-y-2 text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px] mb-4">
            <li>• Teams with the highest points win.</li>
            <li>
              • In case of a tie, the time taken will determine the winner.
            </li>
          </ul>

          <h2 className="text-[#000000] lg:text-2xl min-[400px]:text-[15px] font-semibold mb-2">
            Why Participate?
          </h2>
          <ul className="space-y-2 text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px] mb-20 list-disc list-inside ">
            <li>
              ✅ Tackle Real-World Problems: Work on impactful problem
              statements addressing real-world challenges.
            </li>
            <li>
              🤝 Expert Mentorship: Collaborate with seasoned mentors to refine
              your solutions.
            </li>
            <li>
              ⚖️ Fair & Transparent Judging: Judged by a panel of unbiased
              experts.
            </li>
            <li>
              🎉 Experience the Thrill of the Hackathon: Immerse yourself in a
              high-energy environment.
            </li>
            <li>
              🎁 Amazing Rewards & Swag: Stand a chance to win grand prizes and
              exclusive goodies.
            </li>
          </ul>
        </div>

        {/* Right Section */}
        <div className="md:w-1/3 bg-[#F6F7F8] p-4 rounded-lg shadow h-full">
          <h2 className="text-[#000000]  font-bold lg:text-2xl min-[400px]:text-[15px] mb-4">
            Event Location
          </h2>
          <div className="space-y-4">
            {/* Event Date */}
            <div className="flex items-start space-x-2">
              <img
                src="/assets/date.svg"
                alt="Event Date"
                className="w-6 h-6"
              />
              <div>
                <p className="text-[#000000] lg:text-lg sm:text-md max-[640px]:text-[10px] ">
                  Thursday, January 24, 2025
                </p>
                <p className="text-[#000000]  lg:text-lg sm:text-md max-[640px]:text-[10px]">
                  10:00 AM - 4:00 PM
                </p>
              </div>
            </div>

            {/* Event Location */}
            <div className="flex items-start space-x-2">
              <img
                src="/assets/mapVector.svg"
                alt="Location"
                className="w-6 h-6"
              />
              <div>
                <p className="text-[#000000] lg:text-lg sm:text-md max-[640px]:text-[10px]">
                  Atharva College Of Engineering
                </p>
                <p className="text-[#4A4A4A] lg:text-lg sm:text-md max-[640px]:text-[10px]">
                  Malad Marve Road, Shri Sevantilal Khandwala Marg, Charkop
                  Naka, Malad West, Mumbai 400095, Maharashtra
                </p>
              </div>
            </div>

            {/* Map Image */}
            <div className="w-full">
              <a
                href="https://www.google.com/maps/place/Atharva+College+Of+Engineering/@19.1977247,72.8246825,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7b7c24db49ad:0x973ee0458244da44!8m2!3d19.1977247!4d72.8272574!16s%2Fg%2F11ghfbq52f?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="block border-2 border-gray-300 hover:border-gray-600 transition-colors duration-300"
              >
                <img
                  src="/assets/location.svg"
                  className="object-contain w-full h-auto"
                  alt="Location Map"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* {bingo img} */}
    </div>
  );
};

export default CTFCodecraft;
