
import React, { useEffect, useState } from 'react';

const Timeline = () => {
  const [visibleSections, setVisibleSections] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.id;
          if (entry.isIntersecting) {
            setVisibleSections((prev) => [...new Set([...prev, sectionId])]);
          } else {
            setVisibleSections((prev) => prev.filter((id) => id !== sectionId));
          }
        });
      },
      { threshold: 0.2 } 
    );

    const sections = document.querySelectorAll('.timeline-section');
    sections.forEach((section) => observer.observe(section));

    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  return (
    <div className="relative bg-[#8DC6F2] h-[175vh] py-[10vh] w-full text-white overflow-hidden">
      {/* Timeline Background */}
      <div
        id="timeline-bg"
        className={`absolute left-1/2 top-[18vh]  transform -translate-x-1/2 flex justify-center timeline-section ${
          visibleSections.includes('timeline-bg') ? 'opacity-100 scale-100' : 'opacity-0 scale-75'
        } transition-all duration-1000 ease-in-out`}
      >
        <img
          src="/codecraftpage/timeline/timelinebg.svg"
          alt="Timeline Background"
          className="w-[175vh] h-full object-cover md:w-full md:h-full lg:h-full scale-150"
        />
      </div>

      {/* Timeline Elements */}
      <div className="relative max-w-[100vh] mx-auto flex flex-col items-center justify-start h-full">
        {/* Timeline Frame */}
        <div
          id="timeline-frame"
          className={`absolute bottom-0 z-20 flex justify-center timeline-section ${
            visibleSections.includes('timeline-frame') ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'
          } transition-all duration-1000 ease-in-out`}
        >
          <img
            src="/codecraftpage/timeline/timelineframe.svg"
            alt="Timeline Frame"
            className="md:h-[140vh] h-[134vh]"
          />
        </div>

        {/* Timeline Eclipse */}
        <div
          id="timeline-eclipse"
          className={`absolute bottom-0 z-10 flex justify-center timeline-section
          `}
        >
          <img
            src="/codecraftpage/timeline/timelineEclipse.svg"
            alt="Timeline Eclipse"
            className="w-[60%] h-[60%]"
          />
        </div>

        {/* Start Indicator */}
        <div
          id="start-indicator"
          className={`bg-[#EDD734] rounded-md z-50 absolute flex justify-center top-[20%] w-[15vh] timeline-section ${
            visibleSections.includes('start-indicator') ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'
          } transition-all duration-1000 ease-in-out`}
        >
          <div className="bg-[#EDD734] p-2 border-[3px] border-[#C86521] text-white h-full font-bold rounded-md relative">
            <img
              src="/codecraftpage/timeline/tlArrowStart.svg"
              alt="Start Indicator"
              className="w-[50vh]"
            />
          </div>
        </div>

        {/* Additional Indicators */}
        {/* Day 1 Indicator */}
        <div
          id="day1-indicator"
          className={`rounded-md transform z-50 flex justify-center absolute top-[32.5%] scale-[1.075] sm:scale-125 
          md:scale-100 lg:scale-150 timeline-section ${
            visibleSections.includes('day1-indicator') ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'
          } transition-all duration-1000 ease-in-out`}
        >
          <img
            src="/codecraftpage/timeline/day1Tri.svg"
            alt="Day 1 Indicator"
            className="w-[50vh] sm:w-[70vh] md:w-[65vh] lg:w-[60vh]"
          />
        </div>

        {/* Day 2 Indicator */}
        <div
          id="day2-indicator"
          className={`p-1 rounded-md transform z-50 flex justify-center absolute bottom-[30vh] sm:bottom-[20vh] 
          md:bottom-[25vh] lg:bottom-[30vh] timeline-section ${
            visibleSections.includes('day2-indicator') ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'
          } transition-all duration-1000 ease-in-out`}
        >
          <img
            src="/codecraftpage/timeline/CtfFlag.svg"
            alt="Day 2 Indicator"
            className="w-[45vh] sm:w-[70vh] md:w-[65vh] lg:w-[60vh]"
          />
        </div>

        {/* End Indicator */}
        <div
          id="end-indicator"
          className={`bg-[#EDD734] rounded-md z-50 absolute bottom-[10%] w-[15vh] timeline-section ${
            visibleSections.includes('end-indicator') ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'
          } transition-all duration-1000 ease-in-out`}
        >
          <div className="bg-[#EDD734] border-[2px] border-[#C86521] p-2 text-white font-bold rounded-md relative">
            <img
              src="/codecraftpage/timeline/tlArrowEnd.svg"
              alt="End Indicator"
              className="w-[50vh]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
