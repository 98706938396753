import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LandingPage from "./screens/LandingPage";
import CodeCraft from "./screens/CodeCraft";
import UXPage from "./screens/UXpage";
import DsaPage from "./screens/DsaPage";
import CTFpage from "./screens/CTFpage";
import Brochure from "./screens/Brochure";

function App() {

  return (
    
      <div className="scroll-smooth">
        <Router>
          <div className="App">
            
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/codecraft" element={<CodeCraft />} />
                <Route path="/codecraft/uiux" element={<UXPage />} />
                <Route path="/codecraft/dsa" element={<DsaPage />} />
                <Route path="/codecraft/ctf" element={<CTFpage />} />
                <Route path="/codecraft/brochure" element={<Brochure /> }  />
                <Route path="/event" element={<Navigate to="/codecraft" />} />
              </Routes>
            
          </div>
        </Router>
      </div>

  );
}

export default App;
