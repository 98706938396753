import React from "react";

function AboutEvent() {
  return (
    <div className="relative bg-[#052B48] min-h-fit sm:min-h-screen w-full overflow-hidden">
      {/* Background Noise and Texture */}
      <div className="absolute inset-0 z-0">
        <img
          src="codecraftpage/hero/Noise & Texture.svg"
          alt="Noise & Texture"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Road Images Container */}
      <div className="relative z-10 w-full flex flex-col">
        <div className="w-full">
          {/* Image for md and above */}
          <img
            src="codecraftpage/aboutevent/mainRoad.svg"
            alt="Main Road"
            className="hidden md:block w-full h-full object-contain"
          />
          {/* Image for below md */}
          <img
            src="codecraftpage/aboutevent/phoneRoad.svg"
            alt="Phone Road"
            className="block md:hidden w-full h-full object-contain"
          />
        </div>

        {/* About Event Text */}
        <div className="w-full px-4 sm:px-6 md:px-8 pb-8 sm:pb-12 md:-mt-32 lg:-mt-48">
          <div className="max-w-3xl mx-auto md:mr-24 lg:mr-32 md:ml-auto text-center md:text-right text-white">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 sm:mb-6">
              ABOUT EVENT
            </h1>
            <p className="text-sm sm:text-base md:text-lg leading-relaxed sm:leading-loose max-w-2xl md:ml-auto">
              Presenting CSI "CodeCraft" 2025 flagship tech event organized by
              the CSI Committee of Atharva College of Engineering. This event
              provides a platform for students to showcase their skills in
              Cybersecurity, Data Structures and Algorithms, and UI/UX Design.
              It’s an opportunity to learn, innovate, and compete while solving
              real-world challenges and connecting with like-minded individuals.
              Join us to celebrate talent, teamwork, and technology!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutEvent;