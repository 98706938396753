import React from "react";
import AboutUs from "../components/landingPage/AboutUs";
import Hero from "../components/landingPage/Hero";
import Team from "../components/landingPage/Team";
import WhyToJoin from "../components/landingPage/WhyToJoin";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function LandingPage() {
  return (
    <>
      <Navbar />
      <Hero />
      <AboutUs />
      <WhyToJoin />
      <Team />
      <Footer />
    </>
  );
}

export default LandingPage;
