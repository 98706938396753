import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/codecraft/Hero";
import Timeline from "../components/codecraft/TimelineSection";
import Tent from "../components/codecraft/Tent";
import AboutEvent2 from "../components/codecraft/AboutEvent2";
import Venue from "../components/codecraft/Venue";
import Sponsors from "../components/codecraft/Sponsors";
import PreviousEvents from "../components/codecraft/PreviousEvents";
import Faq from "../components/codecraft/Faq";
import Footer from "../components/Footer";

const CodeCraft = () => {
  return (
    <div className="flex flex-col">
      <Navbar />
      <Hero />
      <AboutEvent2 />
      <Tent />
      <Timeline />
      <Venue />
      <Sponsors />
      <PreviousEvents />
      <Faq />
      <Footer />
    </div>
  );
};

export default CodeCraft;
