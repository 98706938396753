import React , {useEffect}from 'react'
import DSAPage from '../components/codecraft/DsaCodecraft'
import DsaPrize from '../components/codecraft/DsaPrize'
import Navbar from "../../src/components/Navbar";
import Footer from "../../src/components/Footer";

const DsaPage = () => {
  useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
      
  return (
    <div>
    <Navbar/>
    <div className="pt-[120px]">
        <DSAPage/>
        <DsaPrize/>
    </div>
    <Footer/>
    </div>
  )
}

export default DsaPage