import React, { useState, useEffect } from "react";
import Spinner from "../CCSpinner";
const CodeCraftPage = () => {
  const [loading, setLoading] = useState(true);
  const imageSources = [
    "codecraftpage/hero/Noise & Texture.svg",
    "codecraftpage/hero/smallBowTL.svg",
    "codecraftpage/hero/flower.gif",
    "codecraftpage/hero/smallBowBR.svg",
    "codecraftpage/hero/mainRainbow.svg",
    "codecraftpage/hero/LOGO.svg",
    "codecraftpage/hero/blackLoon.svg",
    "codecraftpage/hero/loon.svg",
  ];

  useEffect(() => {
    const loadImages = async () => {
      const promises = imageSources.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      await Promise.all(promises);
      setLoading(false);
    };

    loadImages();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#052B48]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative bg-[#052B48] h-screen w-full text-white">
      <div className="absolute inset-0 z-0">
        <img
          src="assets/Noise & Texture.svg"
          alt="Noise & Texture"
          className="w-full h-full object-cover"
        />
      </div>
      {/* Top Left Rainbow */}
      <div className="absolute bottom-20 left-0 w-[50vw] md:w-[15vw] sm:w-[15vw] z-10">
        <img
          src="codecraftpage/hero/smallBowTL.svg"
          alt="Top Left Rainbow"
          className="w-full"
        />
      </div>
      {/* Sunflower SVG */}
      <div className="absolute top-[20vh] left-[4vh] w-20 md:w-28 z-10">
        <img
          src="codecraftpage/hero/flower.gif"
          alt="Moon"
          className="w-full"
        />
      </div>

      {/* Bottom Right Rainbow */}
      <div className="absolute top-20 right-0 w-[40vw] md:w-[12vw] transform translate-y-1/2">
        <img
          src="codecraftpage/hero/smallBowBR.svg"
          alt="Bottom Right Rainbow"
          className="w-full"
        />
      </div>

      {/* Main Rainbow (Behind Logo) */}
      <div className="absolute top-[47vh] xl:top-[30vh] left-0 w-full z-0">
        <img
          src="codecraftpage/hero/mainRainbow.svg"
          alt="Main Rainbow"
          className="h-auto object-cover w-[100vw]"
        />
      </div>
      {/* Main Content */}
      <div className="relative flex flex-col items-center justify-center h-full z-20">
        {/* Logo */}
        <div className="relative flex flex-col items-center justify-center space-y-1">
          <div className="w-full mt-20 max-w-[55rem] mx-auto z-10">
            <img
              src="codecraftpage/hero/LOGO.svg"
              alt="Title"
              className="w-full h-auto"
              style={{ objectFit: "contain", imageRendering: "auto" }}
              draggable="false"
            />
          </div>
        </div>

        {/* Register Button */}
        <div className="relative flex justify-center items-center mt-28 md:mt-14">
          {/* Back Button */}
          <button
            className="absolute bg-[#000] text-white px-16 py-2 rounded-full font-bold shadow-none flex items-center gap-4 z-0"
            onClick={() =>
              window.open("https://forms.gle/sLwBJSxt4TNAWyMY6", "_blank")
            }
          >
            <span className="text-lg md:text-3xl">Register Now</span>
            <img
              src="codecraftpage/hero/blackLoon.svg"
              alt="Hot Air Balloon"
              className="absolute right-[-5px] top-1/2 transform -translate-y-1/2 w-18 h-20"
            />
          </button>

          {/* Front Button */}
          <button
            className="relative bg-[#f6eedf] text-black px-16 py-2 rounded-full font-bold shadow-none flex items-center gap-4 z-10 hover:translate-y-[-7px] transition-transform"
            onClick={() =>
              window.open("https://forms.gle/sLwBJSxt4TNAWyMY6", "_blank")
            }
          >
            <span className="text-lg md:text-3xl">Register Now</span>
            <img
              src="codecraftpage/hero/loon.svg"
              alt="Hot Air Balloon"
              className="absolute right-[-5px] top-1/2 transform -translate-y-1/2 w-18 h-20"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodeCraftPage;
