import React, { useState, useEffect } from "react";
import Spinner from "../Spinner"; // Import your Spinner component

export default function Hero() {
  const [imgSrc, setImgSrc] = useState("/landingpage/Hero-img.svg");
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const updateImageSrc = () => {
      if (window.innerWidth < 840) {
        setImgSrc("/landingpage/Hero-responsive.svg");
      } else {
        setImgSrc("/landingpage/Hero-img.svg");
      }
    };

    updateImageSrc();

    window.addEventListener("resize", updateImageSrc);

    return () => window.removeEventListener("resize", updateImageSrc);
  }, []);

  // Handle image loading state
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <section className="relative h-[calc(100vh)] bg-gradient-to-b from-[#C7DDFD] to-white">
      {loading && <Spinner />} {/* Show spinner while loading */}
      <img
        src={imgSrc}
        alt="CSI-ACE"
        className={`absolute inset-0 w-full h-full object-cover rounded-[35px] p-5 ${
          loading ? "hidden" : "block"
        }`} // Hide the image until loaded
        onLoad={handleImageLoad} // Set loading to false when image is loaded
      />
      {!loading && ( // Show content only after loading
        <div className="relative z-10 flex flex-col justify-center items-center h-full text-center">
          <h1
            className=" text-6xl sm:text-8xl md:text-9xl font-bold mb-4 font-ethno text-primary p-5 shiny-text"
            data-text="CSI-ACE"
          >
            <span className="inline">CSI-</span>
            <span className="inline">ACE</span>
          </h1>
          <p className="text-xl sm:text-2xl mb-8 font-bai text-primary font-thin">
            Computer Society of India
          </p>
          <a href="/codecraft" className="relative group sm:hidden">
            <img
              src="/codecraftpage/pookie.svg"
              alt="CodeCraft Unicorn"
              className="absolute  -top-10 right-4 h-10 w-auto group-hover:scale-110 transition-transform z-10"
            />
            <img
              src="/codecraftpage/CodeCraftbtn.svg"
              alt="CodeCraft Background"
              className="h-12 w-auto relative"
            />
          </a>
        </div>
      )}
    </section>
  );
}
