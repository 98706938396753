import React from 'react';
import { useNavigate } from 'react-router-dom';

const CtfCard = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/codecraft/ctf'); // Replace '/your-route' with your desired route
  };

  return (
    <div className="relative rounded-2xl w-full max-w-sm mx-auto overflow-hidden">
      <div className="relative">
        <img
          src="/codecraftpage/eventCards/ctf-card.png"
          alt="Capture the Flag"
          className="w-full"
        />
        <div className="absolute inset-12 grid grid-rows-[1fr_auto] items-center justify-items-center text-center">
          {/* Title */}
          <h3 className="font-titania font-bold text-4xl text-white relative -translate-y-7">
            Capture <br /> The Flag
          </h3>

          {/* Button */}
          <button
            className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600"
            onClick={handleButtonClick}
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default CtfCard;