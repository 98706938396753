import React, { useEffect } from 'react';

const Brochure = () => {
  useEffect(() => {
    window.location.replace('https://drive.google.com/file/d/1rQEB2RWhuiRJNK4L8fS20S3PURQPSuBT/view');
  }, []);

  return null; 
};

export default Brochure;