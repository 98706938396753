import React from "react";

const MultiImageCarousel = () => {
  const images = [
    "codecraftpage/previousEvents/linkedin3.svg",
    "codecraftpage/previousEvents/cyber4.svg",
    "codecraftpage/previousEvents/ui3.svg",
    "codecraftpage/previousEvents/github3.svg",
    "codecraftpage/previousEvents/linkedin2.svg",
    "codecraftpage/previousEvents/webdev3.svg",
    "codecraftpage/previousEvents/cyber3.svg",
    "codecraftpage/previousEvents/webdev2.svg",
    "codecraftpage/previousEvents/ui2.svg",
    "codecraftpage/previousEvents/github2.svg",
    "codecraftpage/previousEvents/webdev1.svg",
    "codecraftpage/previousEvents/github1.svg",
    "codecraftpage/previousEvents/cyber2.svg",
    "codecraftpage/previousEvents/linkedin1.svg",
    "codecraftpage/previousEvents/ui1.svg",
    "codecraftpage/previousEvents/cyber1.svg",
    "codecraftpage/previousEvents/final.svg",
    "codecraftpage/previousEvents/inaug.svg",
  ];

  return (
    <div className="relative w-full bg-[#052B48] p-8 overflow-hidden">
      {/* Overlay Image */}
      <img
        src="codecraftpage/hero/Noise & Texture.svg"
        alt="Noise and Texture"
        className="absolute inset-0 w-full h-full object-cover z-10 pointer-events-none"
      />

      {/* Content */}
      <h2 className="relative font-storybook text-5xl font-bold mb-16 text-white text-center z-20">
        Glimpse of Previous Events
      </h2>

      <div className="relative z-20">
        {/* Scrolling Images */}
        <div
          className="flex animate-marquee"
          style={{
            animation: `marquee ${images.length * 4}s linear infinite`,
            width: `calc(${images.length} * 300px + ${
              images.length - 1
            } * 24px)`,
          }}
        >
          {images.concat(images).map((src, index) => (
            <div
              key={index}
              className="relative group hover:rotate-0"
              style={{
                transform: `rotate(${index % 2 === 0 ? "2deg" : "-2deg"})`,
                transition: "all 0.3s ease",
              }}
            >
              <div
                className="w-[300px] h-[350px] relative rounded-lg transform transition-all duration-300 ease-out hover:scale-110 hover:rotate-0 hover:z-50"
                style={{
                  boxShadow:
                    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                }}
              >
                <img
                  src={src}
                  alt={`Image ${index + 1}`}
                  className="w-full h-full object-cover transition-all duration-300"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiImageCarousel;
