import React from "react";

const Venue = () => {
  return (
    <div className="relative flex flex-wrap min-w-full justify-center items-center bg-[#052B48] py-6 px-4 sm:py-2 sm:px-6 md:p-2 lg:py-6 lg:px-16 gap-2 md:gap-4 lg:gap-20 overflow-hidden">
      {/* Overlay Image */}
      <img
        src="codecraftpage/hero/Noise & Texture.svg"
        alt="Noise and Texture Overlay"
        className="absolute inset-0 w-full h-full object-cover z-10 pointer-events-none"
      />

      {/* Left Image */}
      <a
        href="https://maps.app.goo.gl/qxKA83YsjtzCy9xXA"
        target="_blank"
        rel="noopener noreferrer"
        className="flex-1 relative z-20"
        aria-label="View location on Google Maps"
      >
        <div className="relative w-[57vw] h-[35vw] sm:w-[60vw] md:w-[60vw] lg:w-[57vw] lg:h-[20vw]">
          <img
            src="codecraftpage/venue/disco.svg"
            alt="Venue Visual Representation"
            className="hidden sm:block w-full h-full object-cover transition-transform duration-300 transform md:scale-[0.85] lg:scale-100 lg:hover:scale-[1.025] md:hover:scale-[0.90] hover:scale-105 rounded-tl-[1.5rem] rounded-br-[1.5rem]"
            loading="lazy"
          />
          <img
            src="codecraftpage/venue/discoMob.svg"
            alt="Venue Mobile Visual Representation"
            className="block sm:hidden w-full h-full object-cover transition-transform duration-300 transform hover:scale-105 rounded-tl-[1.5rem] rounded-br-[1.5rem]"
            loading="lazy"
          />
        </div>
      </a>

      {/* Right Image */}
      <a
        href="https://maps.app.goo.gl/qxKA83YsjtzCy9xXA"
        target="_blank"
        rel="noopener noreferrer"
        className="flex-1 relative z-20"
        aria-label="View location on Google Maps"
      >
        <div className="relative w-[30vw] h-[35vw] lg:w-[22vw] md:h-[30vw] lg:h-[20vw]">
          <img
            src="codecraftpage/venue/atharvacollege.svg"
            alt="Atharva College Campus"
            className="hidden sm:block w-full h-full object-cover transition-transform duration-300 transform hover:scale-105 rounded-tr-[1.5rem] rounded-bl-[1.5rem]"
            loading="lazy"
          />
          <img
            src="codecraftpage/venue/atharvaMob.svg"
            alt="Atharva College Campus Mobile"
            className="block sm:hidden w-full h-full object-cover transition-transform duration-300 transform hover:scale-105 rounded-tr-[1.5rem] rounded-bl-[1.5rem]"
            loading="lazy"
          />
        </div>
      </a>
    </div>
  );
};

export default Venue;