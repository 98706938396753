import React, { useEffect } from "react";
import CTFCodecraft from "../components/codecraft/ctfCodecraft";
import CtfPrize from "../components/codecraft/Ctfprize";
import Navbar from "../../src/components/Navbar";
import Footer from "../../src/components/Footer";

const CTFpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div>
    <Navbar/>
      <div className="pt-[120px]">
        <CTFCodecraft />
        <CtfPrize />
      </div>
      <Footer/>
    </div>
  );
};

export default CTFpage;
