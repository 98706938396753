import React from 'react';
import { useNavigate } from 'react-router-dom';

const DsaCard = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/codecraft/dsa'); // Replace '/your-route' with your desired route
  };

  return (
    <div className="relative rounded-2xl w-full max-w-sm mx-auto">
      {/* Card Content */}
      <div className="relative">
        <img
          src="/codecraftpage/eventCards/dsa-card.png"
          alt="DSA Coding Background"
          className="w-full"
        />
      </div>

      {/* Grid Layout for Text and Button */}
      <div className="absolute inset-12 grid grid-rows-[1fr_auto] items-center justify-items-center text-center">
        {/* Title */}
        <h3 className="font-titania font-bold text-4xl text-black">
          DSA <br /> Coding
        </h3>

        {/* Button */}
        <button
          className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600"
          onClick={handleButtonClick}
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default DsaCard;