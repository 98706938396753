import React from "react";

const Sponsors = () => {
  return (
    <div className="relative bg-[#052B48] py-12 overflow-hidden">
      {/* Overlay Image */}
      <img
        src="codecraftpage/hero/Noise & Texture.svg"
        alt="Noise and Texture"
        className="absolute inset-0 w-full h-full object-cover z-10 pointer-events-none"
      />

      {/* Title */}
      <div className="relative font-storybook text-white text-5xl font-bold text-center mb-8 z-20">
        Sponsors
      </div>

      {/* Sponsors Images */}
      <div className="relative flex flex-row items-center justify-center gap-24 px-12 z-20">
        {/* Sponsor 1 */}
        <a
          href="https://www.employed.world/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full md:w-[10%] transform hover:scale-110 transition-transform duration-300"
        >
          <img
            src="codecraftpage/sponsors/Logo Square.png"
            alt="Employed World"
            className="w-full h-auto object-contain rounded-xl shadow-lg"
          />
        </a>

        {/* Sponsor 2 */}
        <a
          href="https://friends.figma.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full md:w-[10%] transform hover:scale-110 transition-transform duration-300"
        >
          <img
            src="codecraftpage/sponsors/fof.jpg"
            alt="Friends of Figma"
            className="w-full h-auto object-contain rounded-xl shadow-lg"
          />
        </a>
      </div>
    </div>
  );
};

export default Sponsors;