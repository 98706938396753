import React, { useState } from 'react';

const FAQ = () => {
  const [openQuestions, setOpenQuestions] = useState({});
  
  const faqData = [
    {
      id: 1,
      question: "What are the events included in CodeCraft?",
      answer: "CodeCraft features three main events: UI/UX, DSA (Data Structures and Algorithms), and CTF (Capture The Flag). Each event focuses on specific skills and challenges."
    },
    {
      id: 2,
      question: "Do I need to have prior experience to participate in CodeCraft?",
      answer: "No, prior experience is not required. CodeCraft is designed for participants of all skill levels. Whether you are a beginner or an experienced coder, you can participate and improve your skills."
    },
    {
      id: 3,
      question: "What is the format of the UI/UX event?",
      answer: "The UI/UX event will involve design challenges where participants create user-friendly and visually appealing interfaces based on given problem statements."
    },
    {
      id: 4,
      question: "Can I participate in all three events?",
      answer: "Yes, participants are welcome to register and compete in all three events to showcase their versatility and skillset."
    },
    {
      id: 5,
      question: "Will there be any prizes for winners?",
      answer: "Yes, we will be offering exciting prizes for the winners, including cash rewards, certificates, and opportunities for networking with industry professionals."
    },
    {
      id: 6,
      question: "How is CodeCraft different from the CSI BootCamp event?",
      answer: "While the BootCamp focused on skill-building through workshops and tutorials, CodeCraft is a hands-on, competitive event where participants will put their skills to the test through challenges and problem-solving tasks."
    }
  ];

  const toggleQuestion = (id) => {
    setOpenQuestions(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Split FAQ data to match the design (3 in left column, 3 in right)
  const leftColumnFaqs = faqData.slice(0, 3);
  const rightColumnFaqs = faqData.slice(3);

  return (
    <div className="relative min-h-fit bg-[#052B48] p-8 overflow-hidden">
      {/* Noise Texture Overlay */}
      <img
        src="codecraftpage/hero/Noise & Texture.svg"
        alt="Noise and Texture Overlay"
        className="absolute inset-0 w-full h-full object-cover pointer-events-none"
      />

      <div className="relative max-w-6xl mx-auto z-20">
        <h1 className="font-storybook text-5xl font-bold text-white text-center mb-8">
          Frequently Asked Questions
        </h1>
        
        <div className="grid md:grid-cols-2 gap-6">
          {/* Left Column */}
          <div className="space-y-4">
            {leftColumnFaqs.map((faq) => (
              <div
                key={faq.id}
                className="bg-white rounded-lg overflow-hidden shadow-lg h-auto"
              >
                <button
                  onClick={() => toggleQuestion(faq.id)}
                  className="w-full px-4 py-3 flex justify-between items-center text-left hover:bg-gray-50"
                >
                  <span className="text-base font-medium pr-4">{faq.question}</span>
                  <span className={`text-xl transition-transform duration-300 ease-in-out flex-shrink-0 ${
                    openQuestions[faq.id] ? 'rotate-45' : ''
                  }`}>
                    +
                  </span>
                </button>
                
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openQuestions[faq.id] 
                    ? 'max-h-32 py-3 px-4' 
                    : 'max-h-0'
                }`}>
                  <p className="text-sm text-gray-600 leading-relaxed">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Right Column */}
          <div className="space-y-4">
            {rightColumnFaqs.map((faq) => (
              <div
                key={faq.id}
                className="bg-white rounded-lg overflow-hidden shadow-lg h-auto"
              >
                <button
                  onClick={() => toggleQuestion(faq.id)}
                  className="w-full px-4 py-3 flex justify-between items-center text-left hover:bg-gray-50"
                >
                  <span className="text-base font-medium pr-4">{faq.question}</span>
                  <span className={`text-xl transition-transform duration-300 ease-in-out flex-shrink-0 ${
                    openQuestions[faq.id] ? 'rotate-45' : ''
                  }`}>
                    +
                  </span>
                </button>
                
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openQuestions[faq.id] 
                    ? 'max-h-32 py-3 px-4' 
                    : 'max-h-0'
                }`}>
                  <p className="text-sm text-gray-600 leading-relaxed">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;