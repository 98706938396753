import { useNavigate } from 'react-router-dom';

const UiCard = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/codecraft/uiux'); // Replace '/your-route' with your desired route
  };

  return (
    <div className="relative rounded-2xl w-full max-w-sm mx-auto overflow-hidden">
      {/* Card Content */}
      <div className="relative">
        <img
          src="/codecraftpage/eventCards/ui-card.png"
          alt="UI/UX Hackathon"
          className="w-full"
        />
      </div>

      {/* Grid Layout for Text and Button */}
      <div className="absolute inset-12 grid grid-rows-[1fr_auto] items-center justify-items-center text-center">
        {/* Title */}
        <h3 className="font-bold text-4xl text-black font-titania mt-32"> {/* Added margin-top */}
          UI/UX <br /> Hackathon
        </h3>

        {/* Button */}
        <button
          className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600"
          onClick={handleButtonClick}
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default UiCard;